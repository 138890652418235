
<div class="acceptable-use-policy-container">
    <md-card>
        <md-card-header>
            <h1 class="md-title">Casual Simulation Web Services Acceptable Use Policy</h1>
        </md-card-header>
        <md-card-content>
            <p><em>Last updated on April 6th, 2022</em></p>
            <p>
                You can find a plain-text version of this acceptable use policy
                <a href="/acceptable-use-policy.txt">here</a>.
            </p>
            <p>
                By using our products or services, you agree to this Acceptable Use Policy as
                well as our <a href="/terms">Terms of Service</a> and
                <a href="/privacy-policy">Privacy Policy</a>.
            </p>
            <div class="acceptable-use-policy-content">
                <h3 id="toc">
                    <ol start="0">
                        <li>Table of Contents</li>
                    </ol>
                </h3>
                <div class="table-of-contents">
                    <ol start="0">
                        <li><a href="#toc">Table of Contents</a></li>
                        <li><a href="#about-policy">About this Acceptable Use Policy</a></li>
                        <li><a href="#illegal-use">Illegal Use</a></li>
                        <li><a href="#spam">Spam</a></li>
                        <li>
                            <a href="#identity-theft-and-privacy">Identity Theft and Privacy</a>
                        </li>
                        <li><a href="#hate-content">Hate Content, Defamation, and Libel</a></li>
                        <li>
                            <a href="#disruptions-and-exploits">Disruptions and Exploits</a>
                        </li>
                        <li><a href="#copyright">Copyright</a></li>
                        <li>
                            <a href="#mass-registration">Mass Registration and Automation</a>
                        </li>
                        <li>
                            <a href="#sexually-explicit-material">Sexually Explicit Material</a>
                        </li>
                    </ol>
                </div>
                <h3 id="about-policy">
                    <ol start="1">
                        <li>About this Acceptable Use Policy</li>
                    </ol>
                </h3>
                <p>
                    This Acceptable Use Policy pertains to the legal obligations you assume when
                    you access the following websites:
                </p>
                <ul>
                    <li><a href="https://ab1.bot">ab1.bot</a></li>
                    <li><a href="https://alpha.ab1.bot">alpha.ab1.bot</a></li>
                    <li><a href="https://static.ab1.bot">static.ab1.bot</a></li>
                    <li><a href="https://stable.ab1.bot">stable.ab1.bot</a></li>
                    <li><a href="https://ab1.link">ab1.link</a></li>
                    <li><a href="https://casualos.com">casualos.com</a></li>
                    <li><a href="https://alpha.casualos.com">alpha.casualos.com</a></li>
                    <li><a href="https://static.casualos.com">static.casualos.com</a></li>
                    <li><a href="https://stable.casualos.com">stable.casualos.com</a></li>
                    <li><a href="https://publicos.com">publicos.com</a></li>
                    <li><a href="https://publicos.link">publicos.link</a></li>
                    <li><a href="https://auxplayer.com">auxplayer.com</a></li>
                    <li><a href="https://alpha.auxplayer.com">alpha.auxplayer.com</a></li>
                    <li><a href="https://static.auxplayer.com">static.auxplayer.com</a></li>
                    <li><a href="https://stable.auxplayer.com">stable.auxplayer.com</a></li>
                    <li><a href="https://auxplayer.org">auxplayer.org</a></li>
                    <li><a href="https://static.auxplayer.org">static.auxplayer.org</a></li>
                    <li><a href="https://stable.auxplayer.org">stable.auxplayer.org</a></li>
                    <li><a href="https://auxbackend.com">auxbackend.com</a></li>
                    <li><a href="https://webhook.auxbackend.com">webhook.auxbackend.com</a></li>
                    <li>
                        <a href="https://websocket.auxbackend.com">websocket.auxbackend.com</a>
                    </li>
                    <li><a href="https://docs.casualos.com">docs.casualos.com</a></li>
                    <li><a href="https://docs.ab1.bot">docs.ab1.bot</a></li>
                </ul>
                <p>
                    This Acceptable Use Policy further relates to any access points, endpoints,
                    webhooks, or any other services provided at the below above listed domains
                    (&ldquo;Services&rdquo;) and create a legal agreement between you
                    (&ldquo;you&rdquo; or &ldquo;your&rdquo;) and Casual Simulation, Inc, a
                    501(c)(3) not-for-profit company registered in Michigan whose address is 879
                    4 Mile RD NE, Grand Rapids, MI, 49525 (&ldquo;Casual Simulation,&rdquo;
                    &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;).
                </p>
                <p>
                    If you are using the Site on behalf of you personally, &ldquo;you&rdquo;
                    means you individually; if you represent an organization, &ldquo;you&rdquo;
                    means your organization. You agree that by accessing our Services, you are
                    agreeing to this Acceptable Use Policy, our Terms of Service, and our
                    Privacy Policy. If you do not agree with these terms THEN YOU SHOULD NOT
                    ACCESS OUR SERVICES.
                </p>
                <p>
                    The intent of this document is to clarify what we consider to be acceptable
                    use of the Services.
                </p>
                <p>
                    If you are found to be in violation of any of the below policies, you will
                    receive a notice via the most expedient means we have to contact you at our
                    discretion including email. Unless you explain or correct your behavior
                    within 72 hours your content will be deleted and your access will be
                    suspended or otherwise limited to our Services at our sole discretion. We
                    will do our best to work with you and ensure a fair outcome in all cases. We
                    reserve the right to immediately suspend, without notice, any content,
                    account, or IP address which we determine to be submitting spam or other
                    potentially damaging or disruptive content to the Services.
                </p>
                <p>
                    If we have no means of identifying or reaching you we will immediately
                    suspend, without notice, any content, account, IP address, auxBundle, or
                    source which we determine to be submitting spam or other potentially
                    damaging or disruptive content to the Services. We further reserve the right
                    to suspend or block any source or means of access to the Services we suspect
                    to be damaging and disruptive in any form.
                </p>
                <p>
                    If your access or content has been blocked, suspended, or hindered without
                    notice you may reach us at
                    <a class="c9" href="mailto:hello@casualsimulation.org"
                        >hello@casualsimulation.org</a
                    >
                    to discuss the specifics of your situation. When your account or access is
                    suspended, public access to content contributed by you may be blocked or
                    removed, and your access may be suspended or deleted at our discretion.
                    Access to other functionality or information within the Services may also be
                    blocked or disabled.
                </p>
                <h3 id="illegal-use">
                    <ol start="2">
                        <li>Illegal Use</li>
                    </ol>
                </h3>
                <p>
                    The Services may not be used for illegal purposes. Examples of this include
                    using the Services for fraudulent purposes or operating a phishing site
                    (used to obtain account and password information).
                </p>
                <h3 id="spam">
                    <ol start="2">
                        <li>Spam</li>
                    </ol>
                </h3>
                <p>
                    Users that do not publish meaningful content, use deceptive means to
                    generate revenue or traffic, or whose primary purpose is affiliate
                    marketing, will be suspended or their access impeded or otherwise blocked.
                </p>
                <h3 id="identity-theft-and-privacy">
                    <ol start="3">
                        <li>Identity Theft and Privacy</li>
                    </ol>
                </h3>
                <p>
                    Users that misleadingly appropriate the identity of another person are not
                    permitted. Users may not post other people&#39;s personally identifying or
                    confidential information, including but not limited to credit card numbers,
                    Social Security Numbers, and driver&#39;s and other license numbers. You may
                    not post information such as other people&#39;s passwords, usernames, phone
                    numbers, addresses and e-mail addresses unless already publicly accessible
                    on the Web.
                </p>
                <h3 id="hate-content">
                    <ol start="4">
                        <li>Hate Content, Defamation, and Libel</li>
                    </ol>
                </h3>
                <p>
                    Hate speech and other objectionable content that is unlawful, defamatory,
                    and fraudulent. Note that an allegation of defamatory expression, in and of
                    itself, does not establish defamation. The truth or falsehood of a bit of
                    expression is a key element in establishing defamation, and we are not in a
                    position to make that sort of fact-based judgment. That said, if we have
                    reason to believe that a particular form of media is defamatory (a court
                    order, for example), we will remove that media.
                </p>
                <h3 id="disruptions-and-exploits">
                    <ol start="5">
                        <li>Disruptions and Exploits</li>
                    </ol>
                </h3>
                <p>
                    We will terminate accounts and block addresses of those who attempt
                    unauthorized use of the Services.
                </p>
                <h3 id="copyright">
                    <ol start="6">
                        <li>Copyright</li>
                    </ol>
                </h3>
                <p>
                    Using copyrighted material does not constitute infringement in all cases. In
                    general, however, users should be careful when using copyrighted content
                    without the permission of those who created it.
                </p>
                <h3 id="mass-registration">
                    <ol start="7">
                        <li>Mass Registration and Automation</li>
                    </ol>
                </h3>
                <p>
                    Accounts that are registered automatically or systematically will be removed
                    and access will be permanently suspended.
                </p>
                <h3 id="sexually-explicit-material">
                    <ol start="8">
                        <li>Sexually Explicit Material</li>
                    </ol>
                </h3>
                <p>
                    Accounts or media that use Services to post sexually explicit or
                    pornographic material, or links to it, will be terminated or suspended.
                </p>
            </div>
        </md-card-content>
    </md-card>
</div>


<div class="privacy-policy-container">
    <md-card>
        <md-card-header>
            <h1 class="md-title">{{ hostname }} Privacy Policy</h1>
        </md-card-header>
        <md-card-content>
            <p><em>Last updated on April 6th, 2022</em></p>
            <p>
                You can find a plain-text version of this privacy policy
                <a href="/privacy-policy.txt">here</a>.
            </p>

            <p>
                By using our products or services, you agree to this Privacy Policy as well as
                our
                <a href="/acceptable-use-policy">Acceptable Use Policy</a> and
                <a href="/terms">Terms of Service</a>.
            </p>

            <div class="privacy-policy-content">
                <h3 id="toc">
                    <ol start="0">
                        <li>Table of Contents</li>
                    </ol>
                </h3>
                <div class="table-of-contents">
                    <ol start="0">
                        <li><a href="#toc">Table of Contents</a></li>
                        <li><a href="#introduction">Introduction</a></li>
                        <li><a href="#personal-information">Personal Information</a></li>
                        <li><a href="#cached-data">Cached Data</a></li>
                        <li><a href="#links-from-our-services">Links From Our Services</a></li>
                    </ol>
                </div>

                <h3 id="introduction">
                    <ol start="1">
                        <li>Introduction</li>
                    </ol>
                </h3>
                <p>
                    Casual Simulation offers tools for authoring open source experiences. We at
                    Casual Simulation Inc. strive to respect the privacy of everyone to the best
                    of our ability. This document describes how we collect, process, and store
                    personal data.
                </p>
                <p>
                    To that end, we provide you with this Privacy Policy to advise you of how
                    your data may be collected or used when you access the following websites:
                </p>
                <ul>
                    <li><a href="https://ab1.bot">ab1.bot</a></li>
                    <li><a href="https://alpha.ab1.bot">alpha.ab1.bot</a></li>
                    <li><a href="https://static.ab1.bot">static.ab1.bot</a></li>
                    <li><a href="https://stable.ab1.bot">stable.ab1.bot</a></li>
                    <li><a href="https://ab1.link">ab1.link</a></li>
                    <li><a href="https://casualos.com">casualos.com</a></li>
                    <li><a href="https://alpha.casualos.com">alpha.casualos.com</a></li>
                    <li><a href="https://static.casualos.com">static.casualos.com</a></li>
                    <li><a href="https://stable.casualos.com">stable.casualos.com</a></li>
                    <li><a href="https://publicos.com">publicos.com</a></li>
                    <li><a href="https://publicos.link">publicos.link</a></li>
                    <li><a href="https://auxplayer.com">auxplayer.com</a></li>
                    <li><a href="https://alpha.auxplayer.com">alpha.auxplayer.com</a></li>
                    <li><a href="https://static.auxplayer.com">static.auxplayer.com</a></li>
                    <li><a href="https://stable.auxplayer.com">stable.auxplayer.com</a></li>
                    <li><a href="https://auxplayer.org">auxplayer.org</a></li>
                    <li><a href="https://static.auxplayer.org">static.auxplayer.org</a></li>
                    <li><a href="https://stable.auxplayer.org">stable.auxplayer.org</a></li>
                    <li><a href="https://auxbackend.com">auxbackend.com</a></li>
                    <li><a href="https://webhook.auxbackend.com">webhook.auxbackend.com</a></li>
                    <li>
                        <a href="https://websocket.auxbackend.com">websocket.auxbackend.com</a>
                    </li>
                    <li><a href="https://docs.casualos.com">docs.casualos.com</a></li>
                    <li><a href="https://docs.ab1.bot">docs.ab1.bot</a></li>
                </ul>
                <p>
                    This Privacy Policy further relates to access points, endpoints, webhooks,
                    or any other services provided by this or the above listed websites
                    (&ldquo;Services&rdquo;). This Privacy Policy creates a legal agreement
                    between you (&ldquo;you&rdquo; or &ldquo;your&rdquo;) and Casual Simulation,
                    Inc, a 501(c)(3) not-for-profit company registered in Michigan whose address
                    is 879 4 Mile RD NE, Grand Rapids, MI, 49525 (&ldquo;Casual
                    Simulation,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or
                    &ldquo;our&rdquo;).
                </p>
                <p>
                    If you are using the Services on behalf of you personally, &ldquo;you&rdquo;
                    means you individually; if you represent an organization, &ldquo;you&rdquo;
                    means your organization. You agree that by accessing our Services, you are
                    agreeing to these terms. If you do not agree with these terms THEN YOU
                    SHOULD NOT ACCESS OUR SERVICES.
                </p>
                <h3 id="personal-information">
                    <ol start="2">
                        <li>Personal Information</li>
                    </ol>
                </h3>
                <p>
                    WE DO NOT SHARE OR SELL YOUR DATA OR USER CONTENT INCLUDING ANONYMOUS DATA
                    OR USER CONTENT.
                </p>
                <p>
                    If you wish to use the Services anonymously and free of charge simply launch
                    any instance at <a target="_blank" href="https://ab1.bot">ab1.bot</a> (a new
                    instance is automatically created when you visit the website), create any
                    User Content, and then download your User Content.
                </p>
                <p>
                    If you access the Services at all we reserve the collect anonymous usage
                    data regarding your use of the Services for debugging purposes and to
                    improve the Services. We post publicly available analytics of our website
                    traffic at
                    <a target="_blank" href="https://simpleanalytics.com/ab1.bot"
                        >https://simpleanalytics.com/ab1.bot</a
                    >. WE DO NOT SHARE OR SELL THIS DATA other than described in this paragraph.
                </p>
                <p>
                    You must create an account and login to publish User Content via aux bundles
                    that are not deleted every 24 hours. If you create an account we collect
                    your name and email address and provide your name and email address the
                    following affiliates:
                </p>
                <p>
                    <strong>Magic.link</strong>: we use
                    <a target="_blank" href="https://magic.link/">magic.link</a> for
                    authentication and only provide them your email. Please review
                    magic.link&rsquo;s Terms of Service (<a
                        target="_blank"
                        href="https://magic.link/legal/user-terms"
                        >https://magic.link/legal/user-terms</a
                    >) for more information on how
                    <a target="_blank" href="https://magic.link/">magic.link</a> may use your
                    data.
                </p>
                <p>
                    <strong>Readyplayer.me</strong>: we use
                    <a target="_blank" href="https://readyplayer.me">readyplayer.me</a>&rsquo;s
                    avatar services to create avatars for your use. We provide readyplayer.me
                    your name and email to do so.
                    <br />
                    Please review
                    <a target="_blank" href="https://readyplayer.me">readyplayer.me</a>'s Terms
                    of Service (<a
                        href="https://drive.google.com/file/d/1ER3gBvx1UwXMDiOaZk8akzPmO6LAFfTp/view"
                        >https://drive.google.com/file/d/1ER3gBvx1UwXMDiOaZk8akzPmO6LAFfTp/view</a
                    >) for more information on how
                    <a target="_blank" href="https://readyplayer.me">readyplayer.me</a> may use
                    your data.
                </p>
                <p>
                    After creating an account and logging in, your User Content you publish via
                    aux bundles are stored &ldquo;as is&rdquo; by use of public
                    <a target="_blank" href="https://docs.casualos.com/docs/glossary#record"
                        >CasualOS Records</a
                    >. This means any unencrypted data you publish is publically available and
                    can be accessed by anyone who discovers it. ANY ENCRYPTED DATA YOU PUBLISHED
                    IS STORED &ldquo;AS IS&rdquo; AND WE DO NOT HAVE THE MEANS TO UNENCRYPT IT
                    NOR WILL WE ATTEMPT TO.
                </p>
                <p>
                    We reserve the right to provide any of your data or User Content to a
                    government or government&rsquo;s lawful authorities only when mandated or
                    compelled by law, and we may or may not notify you of this as may be
                    required by law.
                </p>
                <h3 id="cached-data">
                    <ol start="3">
                        <li>Cached Data</li>
                    </ol>
                </h3>
                <p>
                    We sometimes create and utilize cached data on your device as may be
                    necessary, at times, to use our Services or otherwise make our Services
                    easier to use. Cached data is similar to Cookies in that data is stored
                    locally on your device depending upon what portions of the Services you use.
                </p>
                <p>We will NOT use cached data or similar technologies to:</p>
                <ul>
                    <li>identify your IP address or track you</li>
                    <li>
                        store any personally identifiable information except as may be
                        temporarily necessary to facilitate a transaction.
                    </li>
                </ul>
                <p>
                    We or those who use are Services may use cached data and similar
                    technologies:
                </p>
                <ul>
                    <li>
                        to provide you with a better experience via reduced loading times and
                        allow for offline capability
                    </li>
                    <li>
                        to store local data on your device related to a specific experience
                        contained within our Services
                    </li>
                    <li>
                        to allow our Services to interact with a third party social network or
                        platform;
                    </li>
                    <li>to allow a payment processor to process your payment instructions;</li>
                    <li>
                        to provide you with more customized Services, for example to provide our
                        Services in the correct language;
                    </li>
                    <li>
                        to obtain data which will allow us to understand how people are using
                        our Services so that we can improve them.
                    </li>
                </ul>
                <p>
                    By accessing or using our Services you consent to our use of cached data in
                    accordance with this Privacy Policy. You can clear cached data by deleting
                    it from your device.
                </p>
                <p>
                    YOU ACCESS USER CREATED CONTENT AT YOUR OWN RISK AND USER CREATED CONTENT
                    MAY TRACK YOU, PUT ANY KIND OF CASHED DATA ON YOUR DEVICE (INCLUDING
                    MALICIOUS CODE) OR USE YOUR DATA IN OTHER WAYS WE CANNOT FORESEE OR PREVENT.
                </p>
                <h3 id="links-from-our-services">
                    <ol start="4">
                        <li>Links from our Services</li>
                    </ol>
                </h3>
                <p>
                    We may provide links to third party websites or services from our Services.
                    You understand that when you click on these links any data which you provide
                    thereafter is subject to such a third party&#39;s privacy policy and not
                    ours. Although we will endeavor only to link to reputable Services, we take
                    no responsibility for the content, safety, or security of any third party
                    Services.
                </p>
            </div>
        </md-card-content>
    </md-card>
</div>

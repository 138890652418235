
<div>
    <loading
        v-if="loading"
        :status="''"
        :progress="0"
        :error="null"
        :show="loading"
        :version="version"
    >
    </loading>
</div>
